<template>
  <div
      class="main-menu menu-fixed menu-accordion menu-shadow"
      :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
      @mouseenter="updateMouseHovered(true)"
      @mouseleave="updateMouseHovered(false)"
  >

    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">

            <b-link
                class="navbar-brand"
                to="/"
            >
              <span class="brand-logo">
                <b-img
                    style="padding-bottom: 8px;"
                    :src="appLogoImage"
                    alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>


          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                  icon="XIcon"
                  size="20"
                  class="d-block d-xl-none"
                  @click="toggleVerticalMenuActive"
              />
              <feather-icon
                  :icon="collapseTogglerIconFeather"
                  size="20"
                  class="d-none d-xl-block collapse-toggle-icon"
                  @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
        :class="{'d-block': shallShadowBottom}"
        class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <div class="pt-3 pb-3 px-2 mb-0">
        <b-dropdown
            style=" background-color:  rgba(115, 103, 240, 0.12);"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            center
            block
            no-caret
            variant="secondary"
			class="create-new">
          <template #button-content>
            <div style="padding:3px">
              <feather-icon size="18"
                            icon="ArrowRightIcon"
                            class="mr-1"/>
              <strong class="font-medium-2">{{ $t('document.create.heading') }}</strong>
            </div>
          </template>
          <b-dropdown-item @click="openCreateBlankDocument">
            <feather-icon size="18"
                          icon="FileIcon"
                          class="mr-1"/>
            <strong>{{ $t('document.create.document') }}</strong>
          </b-dropdown-item>
          <!--     TODO should open template selection modal. this is a redundant way     -->
          <b-dropdown-item @click="chooseTemplate" class="font-weight-bolder">
            <feather-icon size="18"
                          icon="LayoutIcon"
                          class="mr-1"/>
            <strong>{{ $t('document.create.chooseTemplate') }}</strong>
          </b-dropdown-item>
          <b-dropdown-divider/>
          <b-dropdown-item @click="openImportWord" class="font-weight-bolder">
            <v-icon name="file-word" class="mr-1"></v-icon>
            <strong>{{ $t('document.create.importWord') }}</strong>
          </b-dropdown-item>
          <b-dropdown-item @click="openImportPai" class="font-weight-bolder">
            <img :src="require('@/assets/images/logo/papirai-logo.svg')"
                 height="16"
                 class="mr-1"/>
            <strong>{{ $t('document.importPAI') }}</strong>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <vertical-nav-menu-items
          :items="items"
          class="navigation navigation-main pt-0"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <select-workspace
        v-if="selectWorkspaceEnabled"
        @close="closeCreateBlankDocument"
        :workspaces="workspaces"
        :description="$t('document.documentRequiresWorkspace')"/>

    <import-word-document
        v-if="importWordDocumentEnabled"
        @close="closeImportWord"/>

    <import-pai-document
        v-if="importPaiDocumentEnabled"
        @close="closeImportPai"/>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {BDropdown, BDropdownDivider, BDropdownItem, BImg, BLink, VBPopover, VBTooltip} from 'bootstrap-vue'
import {computed, provide, ref} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {$themeConfig} from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import i18n from "@/libs/i18n";
import {mapState} from "vuex";
import SelectWorkspace from "@/views/Documents/Create/CreateBlankDocumentModal";
import ImportWordDocument from "@/views/Documents/Create/ImportWordDocument";
import ImportPaiDocument from "@/views/Documents/Create/ImportPaiDocument";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    ImportWordDocument,
    ImportPaiDocument,
    BLink,
    BDropdown, BDropdownItem, BDropdownDivider,
    BImg,
    SelectWorkspace,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selectWorkspaceEnabled: false,
      importWordDocumentEnabled: false,
      importPaiDocumentEnabled: false,
    }
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  beforeMount() {
    this.loadDocumentTypes()
    this.loadDocumentStatuses()
    this.fetchMyWorkspaces()
  },

  computed: {
    ...mapState({
      workspaces: state => state.workspaces.myWorkspaces
    }),
  },

  methods: {
    chooseTemplate() {
      this.$router.push({name: 'templates'})
    },
    openCreateBlankDocument() {
      this.selectWorkspaceEnabled = true
    },
    closeCreateBlankDocument() {
      this.selectWorkspaceEnabled = false
    },
    openImportWord() {
      this.importWordDocumentEnabled = true
    },
    closeImportWord() {
      this.importWordDocumentEnabled = false
    },
    openImportPai() {
      this.importPaiDocumentEnabled = true
    },
    closeImportPai() {
      this.importPaiDocumentEnabled = false
    },
    loadDocumentStatuses() {
      this.$store.dispatch('document/getDocumentStatuses')
    },
    loadDocumentTypes() {
      this.$store.dispatch('document/getDocumentTypes')
    },
    fetchMyWorkspaces() {
      this.$store.dispatch('workspaces/fetchOrganizations')
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const {skin} = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const {appName, appLogoImage} = $themeConfig.app

    const items = computed(() => navMenuItems(i18n))

    return {
      items,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

[dir] .show > .dropdown-menu {
  min-width: 100%;
}

[dir] .dropdown-toggle::after {
	display: none;
}

</style>
