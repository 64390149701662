<template>
  <div>
    <b-modal no-close-on-backdrop
             ref="import-word-document-modal"
             lazy
             scrollable
             size="xl"
             @hidden="hideModal"
             :title="$t('document.importPaiDocument')">
      <b-form @submit.prevent>
        <div class="container-fluid">
          <validation-observer
              ref="importedDocumentForm">
            <div class="row d-flex">
              <b-col class="col-xs-12 col-sm-12 col-xl-3 sidebar-sticky">
                <b-row align-h="start" class="mt-2 w-100">
                  <b-col>
                    <b-form-group
                        :label="$t('document.nameYourDocument')"
                        label-for="inputDocName">
                      <b-form-input
                          id="inputDocName"
                          :placeholder="$t('document.name')"
                          v-model="title"
                          class="w-full"
                          disabled>
                      </b-form-input>

                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row align-h="start" class="mt-2 w-100">
                  <b-col>
                    <b-form-group
                        :label="$t('document.chooseWorkspace')" label-for="documentType">
                      <v-select v-model="selectedWorkspace"
                                :options="workspaces"
                                label="name"
                                class="invoice-filter-select"
                                :placeholder="$t('select.workspaces')">
                        <template #selected-option="{ name }">
                      <span class="text-truncate overflow-hidden">
                        {{ name }}
                      </span>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row align-h="start" class="mt-2 w-100">
                  <b-col>
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('document.category')"
                        vid="documentType"
                        rules="required"
                    >
                      <b-form-group
                          :label="$t('document.category')" label-for="documentType">
                        <v-select
                            :state="errors.length > 0 ? false:null"
                            v-model="selectedDocumentType"
                            :options="documentTypes"
                            :label="'displayText'"
                            :placeholder="$t('document.defineDocumentCategory')"
                            disabled>
                        </v-select>
                        <small class="text-danger">{{
                            errors[0] ? $t('document.categoryErrorMessage') : errors[0]
                          }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
<!--                <b-row align-h="start" class="w-100 mt-2">-->
<!--                  <b-col class="col ml-auto w-100"><span class="font-small-3">Statik belgenizi işleyerek değişkenlerin bulunması ve bölümlere ayrılmasını sağlayabiliriz. Bu orjinal dokümanınızın görünümünü değiştirebilir.</span>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--                <b-row align-h="start" class="d-flex w-100 mt-1">-->
<!--                  <b-col style="width: 30px" class="col-auto">-->
<!--                    <b-form-checkbox v-model="convertAsPapirContent"></b-form-checkbox>-->
<!--                  </b-col>-->
<!--                  <b-col class="col ml-auto w-100"><strong>Otomatik bölümlere ayırsın (Beta)</strong></b-col>-->
<!--                </b-row>-->

              </b-col>
              <b-col align="start" class="ml-auto col-xs-12 col-sm-12 col-xl-9 mt-sm-2">
                <b-row align-h="start" class="w-100">
                  <b-col>
                    <div v-if="!showPreview" class="w-100 flex justify-content-center align-content-center"
                         style=" min-height: 100%; height: 100%;">
                      <div
                          class="d-flex cursor-pointer w-100 h-screen h-auto items-center align-items-center  justify-center text-center rounded border-2 border-primary-100"
                          style="background-color: rgba(9, 30, 66, 0.04); border-style: dashed"
                          @dragover="dragover"
                          @dragleave="dragleave"
                          @click.stop="clickFileInput"
                          @drop="drop">
                        <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
                               style="opacity: 0; position: absolute"
                               class="w-px h-px overflow-hidden" @change.stop="handleFile" ref="file"
                               accept=".pai"/>
                        <div
                            class="d-flex justify-content-center align-center w-100 bg-gray-50 "
                            style="height: calc(200px)">

                          <div v-if="paiFile === null"
                               class="d-flex row w-100 justify-content-center align-center my-auto">
                            <span class="font-weight-bolder font-medium-3">{{ $t('document.dropPaiDocumentHere') }}</span>
                            <div class="flex w-100 py-2 ">
                              <b-button
                                  style="height: 40px; "
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  class=" btn-sm-block  elevated-btn small px-2 "
                                  variant="primary">
                                {{ $t('document.importDocument') }}
                              </b-button>
                            </div>
                          </div>
                          <div v-else class="d-flex row w-100 justify-content-center align-center my-auto">
                            <div class="font-weight-bolder font-medium-3">
                              {{ paiFile.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <WordDocumentPreviewModal
                        v-else
                        :content.sync="jsonContent"
                        :text-content.sync="textContent"
                        :html-content="htmlContent"/>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </validation-observer>
        </div>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
              @click="createDocument"
              :disabled="selectedWorkspace === null || selectedDocumentType === null || paiFile === null"
              variant="secondary"
              size="sm"
              class="float-right elevated-btn small px-3">
            {{ $t('document.createDocument') }}
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {BButton, BCol, BForm, BFormGroup, BFormInput, BModal, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import "vue-select/dist/vue-select.css";
import vSelect from 'vue-select'
import WordDocumentPreviewModal from "@/views/Documents/Create/WordDocumentPreviewModal";
import {PEventBus} from "@/services/PEventBus";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import * as that from "@core/utils/filter";

export default {
  name: "WorkspaceSelectionModal",
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BModal,
    vSelect,
    BFormInput,
    BFormGroup,
    WordDocumentPreviewModal,
    ValidationProvider, ValidationObserver,
  },
  props: {
    submitting: Boolean,
    description: String,
    updateOperation: Boolean,
    willUpdatedOnChange: Boolean,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showPreview: false,
      importedDocumentId: null,
      jsonContent: null,
      convertAsPapirContent: false,
      populateTemplateVariables: true,
      isDefault: false,
      title: null,
      selectedDocumentType: null,
      paiFile: null,
      uploadingProgressPercentage: 0,
      uploadStarted: false,
      htmlContent: null,
      textContent: null,
      required,
      selectedWorkspace: null
    }
  },
  computed: {
    ...mapState({
      documentTypes: state => state.document.documentTypes,
      workspaces: state => state.workspaces.myWorkspaces
    }),
    invalid() {
      return this.selectedWorkspace == null
    },
    getNewDocumentUrl() {
      // return `/documents/${uuid.v4()}/editor`;
      return `/documents/new/editor`;
    }
  },
  mounted() {
    let defaultWorkspaceId = localStorage.getItem('defaultWorkspaceId')
    if (defaultWorkspaceId !== null) {
      this.selectedWorkspace = this.workspaces.find(value => value.organizationId === defaultWorkspaceId)
    }
    this.showModal()
    this.defaultDocumentName = i18n.t('document.namelessDocument')

    PEventBus.$on('docx-upload-progress', this.onUploadProgress)
  },
  beforeDestroy() {
    this.$refs['import-word-document-modal'].hide()
    PEventBus.$off('docx-upload-progress', this.onUploadProgress)
  },
  methods: {
    showModal() {
      this.$refs['import-word-document-modal'].show()
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-primary-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-primary-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-primary-300');
    },
    drop(event) {
      event.preventDefault();
      this.createFile(event.dataTransfer.files[0])
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
      return this.convert();
    },
    onChange() {
    },
    cleanStates() {
      this.$store.dispatch('document/cleanVariables')
      this.$store.dispatch('document/refreshDocument')
    },
    hideModal() {
      this.$emit('close')
    },
    clickFileInput() {
      document.getElementById('assetsFieldHandle').click()
    },
    selectAndContinue() {
      if (this.updateOperation) {
        this.$emit('updateWorkspace', this.selectedWorkspace)
      } else if (this.template != null) {
        this.$emit('createFromTemplate',
            {
              workspace: this.selectedWorkspace,
              templateId: this.template.id,
              documentTitle: this.defaultDocumentName
            })
      }
    },
    handleFile(e) {
      console.log(e)
      const file = e.target.files[0];
      if (!file) return;

      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");

      var that = this

      reader.onload = function (evt) {
        const content = JSON.parse(evt.target.result);
        that.title = content.title
        that.documentTypes.forEach(type => {
          if (type.name === content.documentType) {
            that.selectedDocumentType = type
          }
        })

        that.paiFile = file
      }
      reader.onerror = function (evt) {
        console.log("error")
      }

      e.target.value = ''
    },
    createDocument() {
      this.$refs.importedDocumentForm.validate()
          .then(success => {
            if (success) {
              this.showPreview = true

              this.$store.dispatch('document/importFromExportedFormat', {
                file: this.paiFile,
                workspaceId: this.selectedWorkspace.organizationId,
              }).then((response) => {
                if (response.code === 9010) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: i18n.t('messages.document.requiresGroupRelation'),
                      icon: 'AlertTriangeIcon',
                      variant: 'danger'
                    },
                  }, {position: 'top-center'})

                  this.$router.push({name: 'organizations'})
                  this.hideModal()
                } else {
                  this.$router.replace(`/documents/${response.uid}/editor?tab=1`)
                }
              }).catch((error) => {
                this.uploadStarted = false
              })
            }
          })
    },
    onUploadProgress(uploadProgressEvent) {
      this.uploadingProgressPercentage = uploadProgressEvent.percentage
    }
  },
}
</script>


<style lang="scss" scoped>
@media (max-width: 992px) {
  .sidebar-sticky {
    position: relative;
  }
}

@media (min-width: 1200px) {
  .sidebar-sticky {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
}

</style>
