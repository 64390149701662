<template>
  <div class="editor-center-area">
    <div class=" document-sections-area">
      <div>
        <papir-editor
            :index="0"
            id="editor-preview"
            :placeholder="null"
            :context-menu-enabled="false"
            ref="editorRefPreview"
            :section-id="'preview'"
            :disabled="true"
            :access-level="'READ'"
            class="quill-content"
            :editable="false">
        </papir-editor>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import {Quill, quillEditor} from "@/views/Documents/Editor/Component";
import '@/views/Documents/Editor/Extension/variable-module';
import '@/views/Documents/Editor/Extension/index.css'
import "@/views/Documents/Editor/Extension/Blots/span-block"
import "@/views/Documents/Editor/Extension/Blots/heading"
import "@/views/Documents/Editor/Extension/Blots/heading-formatter"
import "@/views/Documents/Editor/Component/quill.extension.css"
import '@/views/Documents/Editor/Extension/papir-numbering-module'

export default {
  name: "WordDocumentPreview",
  components: {
    "PapirEditor": quillEditor,
  },
  props: {
    htmlContent: String,
  },
  data() {
    return {
      PapirQuill: null,
    }
  },
  computed: {},
  mounted() {
    this.PapirQuill = this.$refs[`editorRefPreview`].papirQuill.quill
    this.initContent()
  },
  methods: {
    hideModal() {
      this.$emit('close')
    },
    initContent() {
      console.log('document imported and initiation started')
      if (this.PapirQuill) {
        const delta = this.PapirQuill.clipboard.convert({html: this.htmlContent})
        this.PapirQuill.setContents(delta, 'silent')
        this.$emit('update:content', this.PapirQuill.getContents())
        this.$emit('update:text-content', this.PapirQuill.getText())
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.editor-center-area {
  //@media (max-width: 1200px) {
  //  left: 95px;
  //  width: calc(100vw - 90px);
  //}
  margin-top: unset;
  padding: 0;
  left:0;
  top:0;
  width: 100%;
  background-color: transparent;
  overflow-y: scroll;

  .document-sections-area {
    width: 210mm;
    min-width: 150mm;
    min-height: 297mm;
    padding: 20mm 10mm 20mm 10mm;
    margin: 1mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
}

.editor-center-area::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent;

}

.editor-center-area::-webkit-scrollbar-thumb {
  background: lightgray;
}
</style>
